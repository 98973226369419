<template>
  <div class="container main">
    <div class="base">
      <section class="contents intro1">
        <article class="inner">
          <div>
            <div class="left">
              <div>
                <h2>얼마니</h2>
                <p>판매가격은 판매자가 정하는 판매자의 권리입니다.</p>
                <p>
                  그러나 그 가격에 그 상품을 구매할 권리는 소비자에게 있습니다.
                </p>
                <p>
                  즉, 소비자가 구매하지 않으면 상거래는 이루어질 수 없습니다.
                </p>
                <p>얼마니는 소비자들의 집단지성을 활용한 가격 설문조사를 통해<br />
                  소비자들의 희망 상품 가격을 제시하겠습니다.</p>
                <!-- <p>
                  판매가격은 판매자가 정하는 판매자의 권리입니다.<br />
                  그러나 그 가격에 그 상품을 구매할 권리는 소비자에게
                  있습니다.<br />
                  즉, 소비자가 구매하지 않으면 상거래는 이루어질 수 없습니다.<br />
                  얼마니는 소비자들의 집단지성을 활용한 가격 설문조사를 통해<br />
                  소비자들의 희망 상품 가격을 제시하겠습니다.
                </p> -->
              </div>
            </div>
            <div class="right">
              <div></div>
            </div>
          </div>
        </article>
      </section>
      <section class="contents intro2">
        <article class="inner">
          <div>
            <div class="left">
              <div>
                <h3>구매자</h3>
                <p>"왜 이렇게 비싸지? 너무 비싼 거 아니야?"</p>
                <p>"이 가격에 팔아도 남는 게 있냐?"</p>
                <p>"와 너무 싸다!"</p>
                <div class="img">
                  <img src="@/assets/images/bg03.jpg" alt="" />
                </div>
              </div>
            </div>
            <div class="right">
              <div>
                <h3>판매자</h3>
                <p>"왜 이렇게 안 팔리지? 비싸서 안 팔리나?"</p>
                <p>"가격 인하를 해볼까?"</p>
                <p>"그런데 얼마 인하를 해야 판매가 잘 될까?"</p>
                <div class="img">
                  <img src="@/assets/images/bg04.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </article>
      </section>
      <section class="contents intro3">
        <article class="inner">
          <div>
            <div>
              <h2>얼마니 <img src="@/assets/images/coin.png" alt="" /></h2>
              <p>
                소비자들이 직접 평균희망구매 가격을 도출하여 이를 판매자들이
                판매가격 설정에 참고하여 불필요한 시간과 갈등을 줄여서 원활한
                상거래가 이루어질 수 있도록 노력하겠습니다.
              </p>
            </div>
            <div class="img">
              <img src="@/assets/images/intro3.png" alt="" />
            </div>
          </div>
        </article>
      </section>

      <!-- <section class="contents guide">
        <article class="inner">
          <h3>
            <span>K-POP</span>
            아티스트와 팬들이 마음으로 함께하는
          </h3>
          <h2><span>얼마니 Funding</span></h2>
          <div class="boxWrap flexB">
            <div class="box">
              <h3>
                10,000원부터<br />
                참여 가능
              </h3>
              <img src="@/assets/image/icn-money.svg" alt="money" />
              <div>
                <p>
                  P2P 금융거래가 생소한<br />
                  회원이라면 소액으로 참여하여<br />
                  P2P 경험을 GET
                </p>
              </div>
              <span class="circle"></span>
            </div>
            <div class="box">
              <h3>
                부실 위험 없이<br />
                안전한 설문
              </h3>
              <img src="@/assets/image/icn-funding.svg" alt="funding" />
              <div>
                <p>
                  부실 위험 없이<br />
                  프로젝트 주체자의 사전 검증<br />
                  시스템으로 안전한 설문 운영
                </p>
              </div>
              <span class="circle"></span>
            </div>
            <div class="box">
              <h3>
                참여금 100%<br />
                중도 회수 가능
              </h3>
              <img src="@/assets/image/icn-withdraw.svg" alt="withdraw" />
              <div>
                <p>
                  설문 프로젝트 종료 전<br />
                  참여자가 변심할 경우<br />
                  100% 중도회수 가능<br />
                  <span>(단, 거래수수료 발생 시 참여자 부담)</span>
                </p>
              </div>
              <span class="circle"></span>
            </div>
            <div class="box">
              <h3>
                참여자 100%<br />
                리워드 제공
              </h3>
              <img src="@/assets/image/icn-reward.svg" alt="reward" />
              <div>
                <p>
                  설문 참여자에게<br />
                  다양한 혜택을 제공할 수 있는<br />
                  리워드 시스템 운영
                </p>
              </div>
              <span class="circle"></span>
            </div>
          </div>
          <button class="full" @click="handleMove('/guide')">
            설문 가이드 보기
          </button>
          <img src="@/assets/image/icn-arrow-02.svg" alt="arrowDown" />
        </article>
      </section>
      <section class="contents status">
        <article class="inner">
          <h3><span>392</span>일 동안 <span>19,303</span>건 운영</h3>
          <h2><span>얼마니 Funding</span> <span>프로젝트 운영 현황</span></h2>
          <span>2021년 9월 29일 기준</span>
          <div class="boxWrap flexL">
            <div class="box">
              <span>Artists</span>
              <h3>참여 아티스트</h3>
              <h2>398<span>명</span></h2>
            </div>
            <div class="box">
              <span>Productions</span>
              <h3>참여 기획사</h3>
              <h2>132<span>개사</span></h2>
            </div>
            <div class="box">
              <span>Projects</span>
              <h3>등록 프로젝트</h3>
              <h2>27,394<span>개</span></h2>
            </div>
            <div class="box">
              <span>Funded Projcects</span>
              <h3>성공 프로젝트</h3>
              <h2>19,902<span>개</span></h2>
            </div>
            <div class="box">
              <span>Countries</span>
              <h3>참여 회원 국가</h3>
              <h2 class="big">28<span>개국</span></h2>
            </div>
            <div class="box">
              <span>Members</span>
              <h3>참여 회원</h3>
              <h2>283,392<span>명</span></h2>
            </div>
            <div class="box">
              <span>Total Fund</span>
              <h3>누적 등록 금액</h3>
              <h2 class="small">102,394,394<span>원</span></h2>
            </div>
            <div class="box">
              <span>Average Fund</span>
              <h3>평균 등록 금액</h3>
              <h2 class="small">283,479<span>원</span></h2>
            </div>
          </div>
          <button class="full" @click="handleFund('/funding')">
            가입하고 설문하기
          </button>
          <img src="@/assets/image/icn-arrow-03.svg" alt="arrowDown" />
        </article>
        <article class="inner bottom">
          <div class="boxWrap flex">
            <div class="box">
              <span>Basic</span>
              <h3>일반형 설문</h3>
              <p>음원제작, 스타홍보,<br />행사/공연 기획 등 프로젝트</p>
              <h2>398<span>건</span></h2>
              <button @click="handleMove('/funding')">참여하기</button>
            </div>
            <div class="box">
              <span>Ivestment</span>
              <h3>투자형 설문</h3>
              <p>음원 및 콘텐츠 제작 투자자<br />참여 프로젝트</p>
              <h2>783<span>건</span></h2>
              <button @click="handleMove('/investfunding')">참여하기</button>
            </div>
          </div>
        </article>
      </section> -->
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      moment: moment,
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 8);
  },
  computed: {
    ...mapState(["userId", "isLogin"]),
  },
  methods: {
    handleMove(path) {
      this.$router.push(path);
    },
    handleFund(path) {
      if (this.isLogin) {
        this.$router.push(path);
      } else {
        alert("로그인 후 이용해주세요");
        this.$router.push("/login");
      }
    },
  },
};
</script>
